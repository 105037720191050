import React, { useContext } from "react";
// import { toast } from "react-toastify";
import "./component.css";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../ConfigContext";
import userService from "../service/user.service";
import useToast from "../utils/useToast";

const DailyCheckin = ({ checkin, fetchDailyCheckins }) => {
  const configData = useContext(ConfigContext);
  const toast = useToast();
  const { t } = useTranslation();
  let credits = 0;
  try {
    if(!checkin.new_implement){
      credits = parseFloat(
        checkin.min_days_checkin_bonus_amount / checkin.min_checkin_days
      ).toFixed(2);
    }
  } catch (error) {
    console.log(error);
  }

  const isToday = (index) => index + 1 === checkin.checked_days + 1;
  const isClaimed = (index) => index + 1 <= checkin.checked_days;

  const claimTodayCheckIn = async (today) => {
    try {
      if (today) {
        const { data } = await userService.claimTodayCheckin();
        toast.success("Succesfully Claimed");
        fetchDailyCheckins();
      }
    } catch (err) {
      if(err && err.response && err.response.data && err.response.data.error){
        toast.error(err.response.data.error);
      }else{
        toast.error("Failed to claim");
      }
    }
  };

  return (
    <div className="checkin-container">
      {toast.ToastContainer}
      <div className="day-container">
        {Array.from({ length: checkin.min_checkin_days }).map((item, index) => (
          <div
            className="day-box"
            key={index}
            style={{
              borderColor: configData ? configData.border_color : "",
              ...(configData &&
                configData.checkin_bg && {
                  background: `url(${configData.checkin_bg})`,
                  backgroundSize: "cover",
                  height: "140px"
                }),
            }}
          >
            <div className="day-inner-box">
              {isToday(index) ? (
                <p className="day">{t("Today")}</p>
              ) : (
                <p className="day">
                  {t("Day")} {index + 1}
                </p>
              )}
            </div>
            {/* <img
              src={require("../images/Diggold_FPromo.png")}
              width="30px"
              height="auto"
              alt=""
            /> */}
            {/* <p>{checkin.new_implement?(parseFloat(checkin.min_days_checkin_bonus_amount)+(index*checkin.checkin_amount_interval)):credits} {checkin.checkin_amount_type !== "CA"?"Credits":configData && configData.currency}</p> */}
            <br />
            <br />
            <br />
            <button
              className="daily_claim"
              style={
                isToday(index)
                  ? {
                      backgroundColor: configData
                        ? configData.secondary_color
                        : "",
                      cursor: checkin.claimed_today ? "default" : "pointer",
                    }
                  : {
                      cursor: "default",
                    }
              }
              // disabled={isToday(index) ? checkin.claimed_today : false}
              onClick={() => claimTodayCheckIn(isToday(index))}
            >
              {isClaimed(index) || (isToday(index) && checkin.claimed_today) ? "✅" : "Claim"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyCheckin;
