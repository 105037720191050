import React, { useState, useEffect, useContext, useRef } from "react";
import userService from "../service/user.service";
import Loading from "./Loading";
import { ConfigContext } from "../ConfigContext";
import TransitionsModal from "./common/TransitionsModal";
import TransitionsModalPromotion from "./common/TransitionsModalPromotion";
import { useTranslation } from "react-i18next";
import InfoIcon from '@mui/icons-material/Info';
import PromotionDescription, { formatPromotionMessage } from "./PromotionDescription"
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { DGPayPaymentOptions } from "../../src/pages/Deposit";
import useToast from "../utils/useToast";
// import { toast } from 'react-toastify';

const amountlist = [
    { id: 1, value: "10" },
    { id: 2, value: "30" },
    { id: 3, value: "50" },
    { id: 4, value: "100" },
    { id: 5, value: "200" },
    { id: 6, value: "250" },
    { id: 7, value: "300" },
    { id: 8, value: "500" },
    { id: 9, value: "1000" },
    { id: 10, value: "3000" },
    { id: 11, value: "5000" },
    { id: 12, value: "10000" },
  ];

const Online = ({vendor, title}) => {
    const initialValues = {
        ttype: vendor === "telcopay"?"TEC":"PG",
        amount: '',
        pg: vendor,
    };

    const [values, setValues] = useState(initialValues);
    // const [radioValue, setRadioValue] = useState(1);
    const [promotionList, setPromotionList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isTelcopay, setIsTelcopay] = useState(vendor === "telcopay"?true:false);
    const [selectedTnC, setSelectedTnC] = useState({
        title: '',
        description: ''
    });
    const [validations, setValidations] = useState({
        amount: "",
        non_field_errors: ""
    })
  const [depSuccessful, setDepSuccessful] = useState('');
  const [depTelcopaySuccessful, setDepTelcopaySuccessful] = useState('');
  const navigate = useNavigate();

  const configData = useContext(ConfigContext)
  const {t} = useTranslation();
  const toast = useToast();


    const {
        amount: amountVal,
        non_field_errors: nonVal,
    } = validations

    const input1Ref = useRef()
    const input2Ref = useRef()
    const input3Ref = useRef()
    const input4Ref = useRef()

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }

    // const onChangeRadio = (e) => {
    //     // setRadioValue(e.target.value);
    // };

    const onChangeRadio = (e) => {
        // setRadioValue(e.target.value);
        if (!e.target.checked) {
          setValues((preValues) => {
            const newVals = JSON.parse(JSON.stringify(preValues))
            delete newVals["promotion"]
            return { ...newVals };
          });
        } else {
          setValues((values) => {
            return { ...values, promotion: e.target.value };
          });
        }
      };

    const handleDeposit = async e => {
        e.preventDefault();

        setLoading(true);
        let response;
        let win;

        try {
            if (vendor == "dgpay") {
                win = window.open("about:blank"); 
                values.provider = 10
            }
            if (vendor == "telcopay") {
                if (!values.coupon_type || !values.coupon_code || values.coupon_code.length < 12) {
                    setValidations({
                        ...validations,
                        coupon_type: !values.coupon_type ? "Please select coupon type" : "",
                        coupon_code: !values.coupon_code ? "Please key in coupon code" : values.coupon_code.length < 12 ? "Coupon code must be at least 12 characters" : "",
                    });
                    setLoading(false)
                    return;
                }else{
                    setValidations({
                        ...validations,
                        coupon_type: "",
                        coupon_code: "",
                    });
                    values.amount = 10
                }
            }

            response = await userService.createDeposit(values);
            if (vendor == "telcopay") {
                setDepTelcopaySuccessful(response.data.txid);
                return;
            }
            let url=""
            if (vendor == "dgpay") {
                const url = response.data.action;
                if (url) {
                    win.location = url;
                    navigate('/history')
                } else {
                    win.close();
                    toast.error(t("dgpay_payment_failed"))
                }
                setLoading(false);
                setValues(initialValues);
                return;
            } else if (vendor == "sun2pay") {
                url = JSON.parse(response.data.action.data)[0].payment_url
            } else {
                url = response.data.action.url
            }
            const bankdata = response.data.action.data;

            let form_data = new FormData();

            Object.entries(bankdata).forEach(([key, value]) => {
                form_data.append(`${key}`, value);
            })

            try {

                var form = document.createElement("form");

                form.method = "POST";
                form.action = url;

                let element = [{}];
                Object.entries(bankdata).forEach(([key, value], index) => {
                    element[index] = document.createElement("input");
                    element[index].name = key;
                    element[index].value = value;
                    form.appendChild(element[index]);
                })

                document.body.appendChild(form);
                form.style = "display: none";

                form.submit();

                document.body.removeChild(form);

            } catch (error) {
                console.log("error ==> ", error);
            }

            // TransitionsModal.success({
            //     content: <p>Deposit is successful. <br />
            //         Transaction ID: {response?.data?.id}</p>,
            // });

            // setValues(initialValues);
        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let non_msg;
            let coupon_code_msg;
            let coupon_type_msg;

            if (err.response.data.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err.response.data.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            // if (err?.response?.data?.cc) {
            //     cc_message = err.response.data.cc;
            // }
            setValidations({
                ...validations, amount: amount_msg, non_field_errors: non_msg, coupon_code: coupon_code_msg, coupon_type: coupon_type_msg
            });
        }

        setLoading(false);
    }

    const getPromotions = async () => {
        setLoading(true);
        const promotionListing = await userService.getPromotionsDepositList();
        // const nonObj = {
        //     id: 0,
        //     title: "NO PROMOTION",
        //     active: true
        // }

        // promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);

        setPromotionList(promotionListing.data.filter(v=>v.other.applicable));
        // setSelectedTnC(promotionListing.data[0]);
        // setRadioValue(promotionListing.data[0].id);
        setLoading(false);
    }

    useEffect(() => {
        getPromotions();
    }, [])
    const coupons = [
        {
            type: "dg",
            name: "Digi"
        },
        {
            type: "hl",
            name: "Hotlink"
        },
        {
            type: "cl",
            name: "Celcom"
        },
        {
            type: "um",
            name: "UMobile"
        },
    ]
    function isNumber(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    const handleInputChange = (index, event) => {
        const inputDigits = event.target.value.slice(0, 4);
        setValues(prevValues => {
            const newValues = { ...prevValues, coupon_code: prevValues.coupon_code.substring(0, 4 * index) + inputDigits.padEnd(4, '0') + prevValues.coupon_code.substring(4 * (index + 1)) }
            return newValues;
        })
      };
      useEffect(() =>{
        if(values.coupon_code &&(values.coupon_code.length === 4)){
            input2Ref.current.focus()
        }
        if(values.coupon_code &&(values.coupon_code.length === 8)){
            input3Ref.current.focus()
        }
        if(values.coupon_code &&(values.coupon_code.length === 12)){
            input4Ref.current.focus()
        }
      },[values])
    return (
        <div className="">
            {toast.ToastContainer}
            <h2 className="my-2" style={{color: configData?configData.text_color :""}}>{title}</h2>
            {
                vendor === "telcopay" ? 
                <>
                    {/* <div className="form-group deposit-form-group">
                        <label style={{color: "#fff"}} for="Provider">Select Gateway</label>
                        <select
                        style={{color: "black"}}
                        className="form-control rounded-xs"
                        value={values.coupon_type} placeholder="Select Coupon" 
                        onChange={(e)=>{
                            setValues(prevValues => {
                                const newValues = { ...prevValues, coupon_type: e.target.value }
                                return newValues;
                            })
                        }} 
                        id="Provider" name="Provider">
                        <option value="" style={{color: "#000"}}>Please Select Gateway</option>
                        {
                                coupons.map((coupon, idx) => (
                                    <option value={coupon.type}>
                                    {coupon.name}
                                    </option>
                                    ))
                                }
                                </select>
                            </div> */}
                    <label style={{color: "#fff"}} for="Provider">Select Telco Provider</label>
                    <div className="telcopay_tabs mb-3" style={{ width: "100%" }}>
                    {
                        coupons.map(coupon => (
                            <a href="#" data-active="true" style={{ background: values.coupon_type == coupon.type ? "#596FD6" : "", color: values.coupon_type == coupon.type ? "white" : "" }} 
                            onClick={() => {
                                setValues(prevValues => {
                                    const newValues = { ...prevValues, coupon_type: coupon.type }
                                    return newValues;
                                })
                            }}>
                                {coupon.name}
                            </a>
                        ))
                    }
                    </div>
                    <div className={`${validations.coupon_type === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{validations.coupon_type}</div>
                    <label style={{color: "#fff"}} for="Provider">Enter Coupon Code</label>
                    <div className="text-center d-flex justify-content-center align-items-center coupon-code-box w-100">
                        <input ref={input1Ref} type="text" maxLength="4" name="" id="" onChange={e=> {
                            if (isNumber(e)) {
                                // handleInputChange(0, e)
                                setValues(prevValues => {
                                    if (!prevValues.coupon_code || prevValues.coupon_code.length<4) {
                                        const newValues = { ...prevValues, coupon_code: e.target.value }
                                        // if(prevValues.coupon_code &&(prevValues.coupon_code.length === 3)){
                                        //     input2Ref.current.focus()
                                        // }
                                        return newValues;
                                    }
                                    return prevValues;
                                })
                            }
                        }} /> <span>-</span>                
                        <input ref={input2Ref} type="text" maxLength="4" name="" id="" onChange={e=> {
                            if (isNumber(e)) {
                                // handleInputChange(1, e)
                                setValues(prevValues => {
                                    if (prevValues.coupon_code && (prevValues.coupon_code.length>=4) && (prevValues.coupon_code.length<8)) {
                                        const newValues = { ...prevValues, coupon_code: prevValues.coupon_code.slice(0, 4) + e.target.value }
                                        // if(prevValues.coupon_code && (prevValues.coupon_code.length === 7)){
                                        //     input3Ref.current.focus()
                                        // }
                                        return newValues;
                                    }
                                    return prevValues;
                                })
                            }
                        }} /> <span>-</span>                
                        <input ref={input3Ref} type="text" maxLength="4" name="" id="" onChange={e=> {
                            if (isNumber(e)) {
                                // handleInputChange(2, e)
                                setValues(prevValues => {
                                    if (prevValues.coupon_code && (prevValues.coupon_code.length>=8) && (prevValues.coupon_code.length<12)) {
                                        const newValues = { ...prevValues, coupon_code: prevValues.coupon_code.slice(0, 8) + e.target.value }
                                        return newValues;
                                    }
                                    return prevValues;
                                })
                            }
                        }} /> <span>-</span>  
                        <input ref={input4Ref} type="text" maxLength="4" name="" id="" onChange={e=> {
                            if (isNumber(e)) {
                                // handleInputChange(2, e)
                                setValues(prevValues => {
                                    if (prevValues.coupon_code && (prevValues.coupon_code.length>=12) && (prevValues.coupon_code.length<16)) {
                                        const newValues = { ...prevValues, coupon_code: prevValues.coupon_code.slice(0, 12) + e.target.value }
                                        return newValues;
                                    }
                                    return prevValues;
                                })
                            }
                        }} />
                    </div>
                    <div className={`${validations.coupon_code === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{validations.coupon_code}</div>
                </>
                : null
            }
            { vendor !== "telcopay" &&
            <div className="form-custom form-label my-4">
                <input
                    name="amount"
                    type="text"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder={t("Amount")}
                    onChange={handleNumChange}
                    value={values.amount}
                />
                <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
            </div>
            }
            { vendor === "dgpay" &&
            <div className="form-custom form-label my-4">
                <select
                    style={{color: configData?configData.text_color :""}}
                    className="form-control rounded-xs"
                    value={values.provider} placeholder="Select Provider" onChange={(e)=>setValues({...values,bankcode:e.target.value})} id="Provider" name="Provider">
                    <option value="" style={{color: "#000"}}>{t("Select_A_Value")}</option>
                    {
                        DGPayPaymentOptions(configData && configData.country).map((payment, idx) => (
                            <option value={payment.id}>
                                {payment.name}
                            </option>
                        ))
                    }
                </select>
            </div>
            }
            { vendor !== "telcopay" &&
            <div className="d-flex text-center tab-scroll-wrap justify-content-between flex-wrap">

                {amountlist.filter((item) => {
                    if(vendor === "telcopay"){
                        return (item.value <= 100)
                    }
                    return true
                }).map((item) => (
                    <div className="pr-10 zIndex0 mb-2" key={item.id} style={{ width: "calc(25% - 6px)" }}>
                        <div
                            className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value
                                    ? "border color-highlight"
                                    : "bg-highlight"
                                }`}
                            style={{ width: "100%" }}
                            onClick={() => setValues({ ...values, amount: item.value })}
                        >
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>
            }
            {/* <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div> */}
            <div>
                {
                nonVal && nonVal.map((item, index) => (
                <div key={index}>
                    {formatPromotionMessage(item)}
                </div>
                ))
                }
            </div>
             <>
        {
            vendor !== "telcopay" ? 
            <>
            <h4 className="mt-4" style={{color: configData?configData.text_color :""}}>{t("Bonus")} {t("Selection")} <span style={{color: configData?configData.text_color_secondary:""}}>({t("optional")}):</span></h4>
        <div className="px-2 border border-white">
          {promotionList &&
            promotionList.length > 0 &&
            promotionList
              .filter((item) => item.active)
              .map((item) => {
                return (
                  <div className="bonus-radio_container d-flex align-items-center my-2" key={item.id}>
                    <div className="bonus-selector d-flex position-relative" style={{ overflow: "hidden" }}>
                      <input className="bonus-radio" name="bonus-radio" type="checkbox" id="" value={item.id}
                      onChange={e=>{
                        if (e.target.checked) {
                          setModalOpen(true);
                          setSelectedTnC(item)
                        } else {
                          setSelectedTnC({
                            title: "",
                            description: "",
                          })
                        }
                        onChangeRadio(e)
                      }}
                      checked={item.id == values.promotion}
                      onClick={() => {
                        // setModalOpen(true);
                        if (item.id !== 0) {
                          setValues((values) => {
                            return { ...values, promotion: item.id };
                          })
                        } else {
                          delete values.promotion;
                        }

                      }}
                      />
                      <div className="bonus-select p-2 d-flex justify-content-center align-items-center">
                        <span className="bonus-check">&#10004;</span>
                      </div>
                    </div>
                    <label className="ml-1 text-uppercase font-bold" style={{ color: configData?configData.text_color :"" }} htmlFor="">{item.title}</label>
                  </div>
                );
              })}
        </div> 
        <p className="text-md d-flex mt-2 align-items-center" style={{color: configData?configData.text_color_secondary:"", gap: "5px", lineHeight: 1.5}}><InfoIcon /> {promotionList && promotionList.length ? t("PromotionMessage") : t("PromotionMessage2")}</p>
            </>
        : null
        }
      </>

            <button
                className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
                style={{color: configData?configData.text_color :""}}
                onClick={handleDeposit}
            >
                {t("Deposit")}
            </button>
            {/* <TransitionsModal
                title={'Add New Reload'}
                centered
                open={isTelcopay}
                onClose={() => setIsTelcopay(false)}
            >
             <TelcoPayPopup values={values} setValues={setValues} setIsTelcopay={setIsTelcopay}/>   
            </TransitionsModal> */}
            <TransitionsModal
                title={''}
                centered
                open={depSuccessful ? true : false}
                footer={[]}
                // onClose={() => setDepSuccessful(false)}
            >
                <div className="d-flex align-items-center">
                <Icon icon="ep:success-filled" color="#52c41a" fontSize={22} />
                <p style={{fontSize:"14px", paddingLeft:"10px"}}>{t("Deposit_is_successful")}. <br />
                {t("Transaction_Id")}: {depSuccessful}</p>
                </div>

                <button style={{marginLeft:"auto", background: "#1677ff", fontWeight:"100", fontSize:"15px"}}
                    onClick={() => {
                    setDepSuccessful('')
                    navigate("/history")

                    }}
                    className={`btn btn-sm d-flex py-2 ml-auto rounded-xs text-uppercase
                                mt-3`}>{t("OK")}
                </button>
            </TransitionsModal>
            <TransitionsModal
                title={''}
                centered
                open={depTelcopaySuccessful ? true : false}
                footer={[]}
            >
                <div className="d-flex align-items-center">
                <Icon icon="ep:success-filled" color="#52c41a" fontSize={22} />
                <p style={{fontSize:"14px", paddingLeft:"10px"}}>{t("Deposit_Telcopay_is_successful")}. <br />
                {t("Transaction_Id")}: {depTelcopaySuccessful}</p>
                </div>

                <button style={{marginLeft:"auto", background: "#1677ff", fontWeight:"100", fontSize:"15px"}}
                    onClick={() => {
                    setDepTelcopaySuccessful('')
                    navigate("/history")

                    }}
                    className={`btn btn-sm d-flex py-2 ml-auto rounded-xs text-uppercase
                                mt-3`}>{t("OK")}
                </button>
            </TransitionsModal>
            <TransitionsModalPromotion
                title={selectedTnC.title}
                className="antd-promotion-modal"
                footer={[]}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <PromotionDescription
                  field={selectedTnC.description}
                  data={""}
                />
            </TransitionsModalPromotion>
            {
                isLoading && <Loading />
            }
        </div>
    );
};

export default Online;
